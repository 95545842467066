/* eslint-disable camelcase */
class Alert {
    constructor(data = {}) {
        const {
            pk,
            tenant,
            meter,
            is_muted,
            is_read,
            level,
            created_at,
            updated_at,
            params,
            title,
            type,
            body,
            color
        } = data

        this.id = pk || null
        this.tenant = tenant || ''
        this.meter = meter || ''
        this.isMuted = is_muted || ''
        this.isRead = is_read || ''
        this.level = level || ''
        this.createdAt = created_at || ''
        this.updatedAt = updated_at || ''
        this.params = params || ''
        this.title = title || ''
        this.type = type || ''
        this.body = body || ''
        this.color = color || ''
    }
}

export default Alert
