import { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import { useSelector } from 'react-redux'

import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi'
import { BsSpeedometer, BsCameraFill, BsFillBellFill } from 'react-icons/bs'
import { FaFileUpload, FaFile } from 'react-icons/fa'
import { FiUserCheck, FiUserPlus, FiUserX } from 'react-icons/fi'
import { Checkbox, Table, Tooltip } from 'evergreen-ui'

import userState from '../../../static/userStatus'
import { getState } from '../../../utils/renderState'

import UserExpandedRow from './UserExpandedRow'
import { UserMobileRow } from './UserMobileRow'

const UserStatusIcon = ({ user }) => {
    if (user.state === userState.VERIFIED) return <FiUserCheck className="verfied_user" />
    if (user.state === userState.REJECTED) return <FiUserX className="rejected_user" />
    if (user.state === userState.NEW) return <FiUserPlus className="new_user" />
    return null
}

const UserDetailRow = ({
    isExpanded,
    user,
    onVerifyUserClick,
    onExpandChange,
    onSendInvoiceClick,
    onRowSelected,
    isSelectedEveryRow,
    isSelectedEveryRowIndeterminate
}) => {
    const [isSelected, setIsSelected] = useState(false)

    const isModerator = useSelector((state) => state.profile.profile.isModerator)
    const tenant = useSelector((state) => state.tenant.tenants[user?.tenant])
    const isMobile = useSelector((state) => state.profile.isMobile)

    const handleSelectClick = (e) => {
        const newState = e.target.checked
        setIsSelected(newState)
        onRowSelected(user.pk)
    }

    useEffect(() => {
        if (isSelectedEveryRow) setIsSelected(isSelectedEveryRow)
        else if (!isSelectedEveryRow && !isSelectedEveryRowIndeterminate) setIsSelected(false)
    }, [isSelectedEveryRow, isSelectedEveryRowIndeterminate])

    const renderRowClassName = () => {
        let className = 'row'

        if (isModerator && user.state === 'new') className = className.concat(' not_verified')
        if (!isModerator && user.state === 'rejected') className = className.concat(' not_verified')

        if (isSelected) className = className.concat(' selected')
        if (isExpanded) className = className.concat(' in_color')

        return className
    }

    const renderUserName = () => {
        const userName = user?.isCompany ? user?.companyName : user?.nameSurname
        return userName === '' ? '-' : userName
    }

    if (isMobile)
        return (
            <UserMobileRow
                user={user}
                isModerator={isModerator}
                isExpanded={isExpanded}
                onExpandChange={onExpandChange}
            />
        )

    return (
        <Table.Row className={renderRowClassName()}>
            {isModerator && (
                <Table.TextCell flexBasis="40px" flexGrow={0} flexShrink={0}>
                    <Checkbox checked={isSelected} onChange={(e) => handleSelectClick(e)} />
                </Table.TextCell>
            )}
            <Table.TextCell>{renderUserName()}</Table.TextCell>
            {isModerator && <Table.TextCell isNumber>{user.externalId}</Table.TextCell>}
            <Table.TextCell>{user.isCompany ? 'Instytucjonalny' : 'Indywidualny'}</Table.TextCell>
            <Table.TextCell>
                <div className="user_state_container">
                    <Tooltip content={getState(user.state)} showDelay={500}>
                        <span className="icon">
                            <UserStatusIcon user={user} />
                            {!isModerator && getState(user.state)}
                        </span>
                    </Tooltip>
                    {isModerator && user.state !== 'verified' ? (
                        <button type="button" onClick={onVerifyUserClick} className="button">
                            Sprawdź dane
                        </button>
                    ) : null}
                </div>
            </Table.TextCell>
            {!isModerator && <Table.TextCell>{tenant?.name ? tenant.name : '-'}</Table.TextCell>}
            <Table.TextCell flexBasis="220px" flexShrink={0} flexGrow={0}>
                <div className={`button_container ${isExpanded ? 'container_expanded' : ''}`}>
                    {!isExpanded ? (
                        <>
                            <Tooltip content="Przejdź do liczników" showDelay={500}>
                                <Link to={`/meters?user_id=${user.pk}`}>
                                    <button type="button" className="button">
                                        <BsSpeedometer size="18" color="#142c66" />
                                    </button>
                                </Link>
                            </Tooltip>
                            <Tooltip content="Przejdź do odczytów" showDelay={500}>
                                <Link to={`/readings?user_id=${user.pk}`}>
                                    <button type="button" className="button">
                                        <BsCameraFill size="18" color="#142c66" />
                                    </button>
                                </Link>
                            </Tooltip>
                            <Tooltip content="Przejdź do dokumentów" showDelay={500}>
                                <Link to={`/documents?user_id=${user.pk}`}>
                                    <button type="button" className="button">
                                        <FaFile size="18" color="#142c66" />
                                    </button>
                                </Link>
                            </Tooltip>
                            <Tooltip content="Przejdź do powiadomień" showDelay={500}>
                                <Link to={`/notifications?user_id=${user.pk}`}>
                                    <button type="button" className="button">
                                        <BsFillBellFill size="18" color="#142c66" />
                                    </button>
                                </Link>
                            </Tooltip>
                            {isModerator && (
                                <Tooltip content="Wyślij dokument" showDelay={500}>
                                    <Link to={`/documents/add/${user.pk}`}>
                                        <button type="button" className="button">
                                            <FaFileUpload size="18" color="#142c66" />
                                        </button>
                                    </Link>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                </div>
            </Table.TextCell>
            <Table.TextCell className="gap" />
            <Table.TextCell className="alone_row">
                <Tooltip
                    content="Szczegóły"
                    showDelay={500}
                    key={!isExpanded ? 0 : 1}
                    position="top"
                >
                    <button type="button" onClick={onExpandChange} className="clickable">
                        {isExpanded ? (
                            <BiUpArrowAlt size="20" className="icon_white" />
                        ) : (
                            <BiDownArrowAlt size="20" />
                        )}
                    </button>
                </Tooltip>
            </Table.TextCell>
        </Table.Row>
    )
}

const UserRow = ({
    user = {},
    sendInvoiceCall,
    setIsVerifyUserPopup,
    setIsUserHistoryPopup,
    setSelectedUser,
    onRowSelected,
    isSelectedEveryRow,
    isSelectedEveryRowIndeterminate
}) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const [urlParams, setUrlParams] = useSearchParams()

    const handleSendInvoiceClick = () => {
        sendInvoiceCall((current) => !current)
        setSelectedUser(user)
    }

    const handleDisplayHistoryClick = () => {
        setIsUserHistoryPopup((current) => !current)
        setSelectedUser(user)
    }

    const handleVerifyUserClick = () => {
        setSelectedUser(user)
        setIsVerifyUserPopup((current) => !current)
    }

    const handleExpandChange = () => {
        setIsExpanded((current) => !current)
    }

    useEffect(() => {
        const userParam = parseInt(urlParams.get('user_id'), 10)
        if (!Number.isNaN(userParam)) {
            if (userParam === user.id) setIsExpanded(true)
        }
    }, [])

    return (
        <>
            <UserDetailRow
                isExpanded={isExpanded}
                user={user}
                onVerifyUserClick={handleVerifyUserClick}
                onExpandChange={handleExpandChange}
                onSendInvoiceClick={handleSendInvoiceClick}
                onRowSelected={onRowSelected}
                isSelectedEveryRow={isSelectedEveryRow}
                isSelectedEveryRowIndeterminate={isSelectedEveryRowIndeterminate}
            />
            <UserExpandedRow
                isExpanded={isExpanded}
                user={user}
                onDisplayHistoryClick={handleDisplayHistoryClick}
                onSendInvoiceClick={handleSendInvoiceClick}
            />
        </>
    )
}

export default UserRow
