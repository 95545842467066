import { createSlice } from '@reduxjs/toolkit'

export const consumptionSlice = createSlice({
    name: 'consumption',
    initialState: {
        consumption: {},
        count: 0,
        nextPage: null,
        previousPage: null
    },

    reducers: {
        addConsumption: (state, action) => {
            state.consumption = action.payload.consumption
            state.count = action.payload.count
            state.nextPage = action.payload.nextPage
            state.previousPage = action.payload.previousPage
        }
    }
})

export const { addConsumption } = consumptionSlice.actions
export default consumptionSlice.reducer
