import { v4 as uuid } from 'uuid'

/* eslint-disable camelcase */
class Measurement {
    constructor(data = {}) {
        const { pk, meter, value, image, created_at, updated_at, state, is_document, index } = data

        this.id = uuid()
        this.meter = meter || ''
        this.value = value || 0
        this.image = image || ''
        this.state = state || ''
        this.createdAt = created_at || new Date().toLocaleDateString()
        this.updatedAt = updated_at || new Date().toLocaleDateString()
        this.isDocument = is_document
        this.index = index
    }
}

export default Measurement
