import { createSlice } from '@reduxjs/toolkit'

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState: {
        test: {},
        alerts: {},
        count: 0,
        nextPage: null,
        previousPage: null
    },

    reducers: {
        addAlerts: (state, action) => {
            state.alerts = action.payload.alerts
            state.count = action.payload.count
            state.nextPage = action.payload.nextPage
            state.previousPage = action.payload.previousPage
        }
    }
})

export const { addAlerts } = alertsSlice.actions
export default alertsSlice.reducer
