/* eslint-disable camelcase */
class Meter {
    constructor(data = {}) {
        const {
            pk,
            tenant_member,
            user,
            name,
            type,
            unit,
            serial_number,
            image,
            street,
            city,
            zip_code,
            radio_id,
            created_at,
            updated_at,
            index
        } = data

        this.pk = pk || null
        this.tenantMember = tenant_member || ''
        this.user = user || null
        this.name = name || ''
        this.type = type || ''
        this.unit = unit || ''
        this.serialNumber = serial_number || ''
        this.image = image || ''
        this.street = street || ''
        this.city = city || ''
        this.zipCode = zip_code || ''
        this.radioId = radio_id || ''
        this.createdAt = created_at || ''
        this.updatedAt = updated_at || ''
        this.index = index
    }
}

export default Meter
