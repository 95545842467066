import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { MdOutlineImage, MdOutlineImageNotSupported } from 'react-icons/md'

import { Dialog, Table, Checkbox } from 'evergreen-ui'
import Zoom from 'react-medium-image-zoom'

import useApi from '../../hooks/useApi'

import NoResultsRow from '../ListRows/NoResultsRow'

import { addMeasurements } from '../../redux/slices/measurementSlice'
import { addMeters } from '../../redux/slices/meterSlice'

import { fromMeterApi } from '../../utils/Adapters/MeterApiAdapter'
import { fromMeasurementApi } from '../../utils/Adapters/MeasurementApiAdapter'
import dateConvert from '../../utils/formatDate'
import { renderState } from '../../utils/renderStateMeasurement'
import getPathArguments from '../../utils/pathIDs'

const Row = ({ measurement, meter, onRowSelected, selectedMeasurements, onImageClick }) => {
    const [isSelected, setIsSelected] = useState(selectedMeasurements.includes(measurement.id))
    const [isClickEnabled, setIsClickEnabled] = useState(true)

    const handleSelectClick = () => {
        if (isClickEnabled) {
            setIsSelected((current) => !current)
            onRowSelected(measurement.id)
        }
    }

    return (
        <Table.Row
            className="row documents__UserDialog__Row"
            key={measurement.id}
            onClick={handleSelectClick}
        >
            <Table.TextCell flexBasis="40px" flexGrow={0} flexShrink={0}>
                <Checkbox checked={isSelected} />
            </Table.TextCell>
            <Table.TextCell>
                <div className="renderImageButtonDiv">
                    <button
                        type="button"
                        className="pictureButton"
                        onClick={() => onImageClick(measurement.image)}
                        onMouseEnter={() => setIsClickEnabled(false)}
                        onMouseLeave={() => setIsClickEnabled(true)}
                        disabled={!measurement.image}
                    >
                        {measurement.image ? (
                            <MdOutlineImage size="18" fill="green" />
                        ) : (
                            <MdOutlineImageNotSupported size="18" fill="red" />
                        )}
                        {measurement.value ? measurement.value.replace('.', ',') : 'Zdjęcie'}
                    </button>
                </div>
            </Table.TextCell>
            <Table.TextCell>{dateConvert(measurement.createdAt)}</Table.TextCell>
            <Table.TextCell>
                <div className="renderImageButtonDiv">
                    <button
                        type="button"
                        className="pictureButton"
                        onClick={() => onImageClick(meter?.image)}
                        onMouseEnter={() => setIsClickEnabled(false)}
                        onMouseLeave={() => setIsClickEnabled(true)}
                        disabled={!meter?.image}
                    >
                        {meter?.image ? (
                            <MdOutlineImage size="18" fill="green" />
                        ) : (
                            <MdOutlineImageNotSupported size="18" fill="orange" />
                        )}
                        {meter?.serialNumber ? meter.serialNumber : 'Zdjęcie'}
                    </button>
                </div>
            </Table.TextCell>
            <Table.TextCell>{renderState(measurement.state, 16)}</Table.TextCell>
        </Table.Row>
    )
}

const TableRows = ({ measurements, meters, onRowSelected, selectedMeasurements, onImageClick }) => {
    const data = Object.values(measurements).filter((measurement) => !measurement.isDocument)

    if (data.length === 0) {
        return <NoResultsRow text="Brak odczytów rozliczeniowych" />
    }

    return data.map((measurement) => {
        return (
            <Row
                measurement={measurement}
                meter={meters[measurement.meter]}
                key={measurement.id}
                onRowSelected={onRowSelected}
                selectedMeasurements={selectedMeasurements}
                onImageClick={onImageClick}
            />
        )
    })
}

export const SelectMeasurementsDialog = ({
    isOpen,
    setIsOpen,
    user,
    onRowSelected,
    selectedMeasurements
}) => {
    const dispatch = useDispatch()

    const [searchText, setSearchText] = useState(null)
    const [isImageShown, setIsImageShown] = useState(false)
    const [imageUrl, setImageUrl] = useState()

    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const measurements = useSelector((state) => state.measurement.measurements)
    const meters = useSelector((state) => state.meter.meters)

    const apiLimit = 20

    const buildURL = () => {
        let url = `/app/meters/moderator/iot/measurement/?limit=${apiLimit}&state=verified&meter__tenant_member=${user}`

        if (searchText) url += `&search=${searchText}`

        return url
    }

    const fetchMeasurements = useApi({
        url: buildURL(),
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeasurementApi,
        enabled: isOpen,
        queryName: ['measurement', isOpen, searchText],
        keepPreviousData: true,
        onSuccess: (data) => dispatch(addMeasurements(data))
    })

    useApi({
        url: `/app/meters/moderator/meter/?id__in=${getPathArguments(measurements, 'meter')}`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeterApi,
        queryName: ['metersByID', measurements],
        enabled: fetchMeasurements.isSuccess,
        onSuccess: (data) => {
            dispatch(addMeters(data))
        },
        keepPreviousData: true
    })

    const handleSearch = (text) => {
        if (text.length <= 2) {
            setSearchText(null)
            return
        }
        setSearchText(text)
    }

    const handleImageClick = (url) => {
        setImageUrl(url)
        setIsImageShown(true)
    }

    return (
        <>
            <Dialog
                isShown={isOpen}
                hasFooter={false}
                onCloseComplete={() => setIsOpen(false)}
                title="Wybór odczytów"
                width="80%"
            >
                <Table className="table">
                    <Table.Head className="header">
                        <Table.HeaderCell flexBasis="40px" flexGrow={0} flexShrink={0} />
                        <Table.HeaderCell>Wartość odczytu</Table.HeaderCell>
                        <Table.HeaderCell>Data odczytu</Table.HeaderCell>
                        <Table.HeaderCell>Numer licznika</Table.HeaderCell>
                        <Table.SearchHeaderCell placeholder="Szukaj..." onChange={handleSearch} />
                    </Table.Head>
                    <Table.Body>
                        <TableRows
                            measurements={measurements}
                            meters={meters}
                            selectedMeasurements={selectedMeasurements}
                            onRowSelected={onRowSelected}
                            onImageClick={handleImageClick}
                        />
                    </Table.Body>
                </Table>
            </Dialog>
            <Dialog
                isShown={isImageShown}
                title="Zdjęcie"
                onCloseComplete={() => setIsImageShown(false)}
                hasFooter={false}
            >
                <Zoom>
                    <div className="dialog_image_container">
                        <img src={imageUrl} alt="meter" />
                    </div>
                </Zoom>
            </Dialog>
        </>
    )
}

export default SelectMeasurementsDialog
