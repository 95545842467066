/* eslint-disable camelcase */
class Consumption {
    constructor(data = {}) {
        const {
            pk,
            meter,
            consumption,
            consumption_ts,
            start_ts,
            start_value,
            end_ts,
            end_value,
            index
        } = data

        this.id = pk || null
        this.meter = meter || ''
        this.consumption = consumption || 0
        this.consumptionTimestamp = consumption_ts || ''
        this.startTimestamp = start_ts || ''
        this.startValue = start_value || ''
        this.endTimestamp = end_ts || ''
        this.endValue = end_value || ''
        this.index = index
    }
}

export default Consumption
