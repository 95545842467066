import { useSelector } from 'react-redux'

import { Link } from 'react-router-dom'

import { IoMdCopy } from 'react-icons/io'
import { BsSpeedometer, BsCameraFill, BsClockHistory, BsFillBellFill } from 'react-icons/bs'
import { FaFile, FaFileUpload } from 'react-icons/fa'
import { SiCodeforces } from 'react-icons/si'
import { toaster, Tooltip } from 'evergreen-ui'

import dateConvert from '../../../utils/formatDate'

const UserExpandedRow = ({ isExpanded, user, onDisplayHistoryClick, onSendInvoiceClick }) => {
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    const handleCopyClick = () => {
        navigator.clipboard.writeText(user.email)
        toaster.notify('Skopiowano adres do schowka')
    }

    const handleCopyNipClick = () => {
        navigator.clipboard.writeText(user.nip)
        toaster.notify('Skopiowano NIP do schowka')
    }

    return (
        <div className={`more_info ${isExpanded ? 'expanded' : 'hidden'}`}>
            <div className="more_info_content">
                <div className="column">
                    <p id="title">Szczegóły odbiorcy </p>
                    <div className="data_grid">
                        {isModerator && (
                            <>
                                <p>Nazwa odbiorcy:</p>
                                <p>{user.pk}</p>
                            </>
                        )}
                        {isModerator && (
                            <>
                                <p>ID odbiorcy:</p>
                                <p>{user.externalId}</p>
                            </>
                        )}
                        <p>Nr klienta:</p>
                        <p>{user.clientNumber ? user.clientNumber : '-'}</p>
                        <p>Data rejestracji:</p>
                        <p>{dateConvert(user.createdAt)}</p>
                        {isModerator && (
                            <>
                                <p className="inLine">E-mail:</p>
                                <p className="inLine">
                                    {user.email}
                                    <Tooltip content="Skopiuj adres email" showDelay={500}>
                                        <button
                                            type="button"
                                            className="clearButton"
                                            onClick={handleCopyClick}
                                        >
                                            <IoMdCopy size={20} />
                                        </button>
                                    </Tooltip>
                                </p>
                            </>
                        )}
                        <p id="spacer_row">Dane rozliczeniowe:</p>
                        {user.isCompany ? (
                            <>
                                <p style={{ textIndent: '1em' }}>Nazwa firmy:</p>
                                <p>{user.companyName ? user.companyName : '-'}</p>
                                <p style={{ textIndent: '1em' }} className="inLine email">
                                    NIP:
                                </p>
                                <p className="inLine">
                                    {user.nip ? user.nip : '-'}
                                    {user.nip ? (
                                        <button
                                            type="button"
                                            className="clearButton"
                                            onClick={handleCopyNipClick}
                                        >
                                            <IoMdCopy size={20} />
                                        </button>
                                    ) : null}
                                </p>
                            </>
                        ) : (
                            <>
                                <p style={{ textIndent: '1em' }}>Nazwa odbiorcy:</p>
                                <p>{user.nameSurname ? user.nameSurname : '-'}</p>
                                <p style={{ textIndent: '1em' }}>Ulica i numer:</p>
                                <p>{user.street ? user.street : '-'}</p>
                                <p style={{ textIndent: '1em' }}>Kod pocztowy:</p>
                                <p>{user.zipCode ? user.zipCode : '-'}</p>
                                <p style={{ textIndent: '1em' }}>Miasto:</p>
                                <p>{user.city ? user.city : '-'}</p>
                            </>
                        )}
                    </div>
                </div>
                <div className="column">
                    <p id="title">Dostępne działania</p>
                    <div className="button_container">
                        <div className="column_right">
                            <Tooltip content="Przejdź do liczników" showDelay={500} position="left">
                                <Link to={`/meters?user_id=${user.pk}`}>
                                    <button type="button" className="button">
                                        <BsSpeedometer size="18" color="#142c66" /> Liczniki
                                    </button>
                                </Link>
                            </Tooltip>
                            <Tooltip content="Przejdź do odczytów" showDelay={500} position="left">
                                <Link to={`/readings?user_id=${user.pk}`}>
                                    <button type="button" className="button">
                                        <BsCameraFill size="18" color="#142c66" /> Odczyty
                                    </button>
                                </Link>
                            </Tooltip>
                            <Tooltip
                                content="Przejdź do dokumentów"
                                showDelay={500}
                                position="left"
                            >
                                <Link to={`/documents?user_id=${user.pk}`}>
                                    <button type="button" className="button">
                                        <FaFile size="18" color="#142c66" /> Dokumenty
                                    </button>
                                </Link>
                            </Tooltip>
                            <Tooltip content="Przejdź do zużycia" showDelay={500} position="left">
                                <Link to={`/consumption?user_id=${user.pk}`}>
                                    <button type="button" className="button">
                                        <SiCodeforces size="18" color="#142c66" /> Zużycie
                                    </button>
                                </Link>
                            </Tooltip>
                        </div>
                        <div className="column_right">
                            <Tooltip
                                content="Przejdź do powiadomień"
                                showDelay={500}
                                position="right"
                            >
                                <Link to={`/notifications?user_id=${user.pk}`}>
                                    <button type="button" className="button">
                                        <BsFillBellFill size="18" color="#142c66" /> Powiadomienia
                                    </button>
                                </Link>
                            </Tooltip>
                            <Tooltip content="Pokaż historię" showDelay={500} position="right">
                                <button
                                    type="button"
                                    className="button"
                                    onClick={onDisplayHistoryClick}
                                >
                                    <BsClockHistory size="18" color="#142c66" /> Historia zmian
                                </button>
                            </Tooltip>
                            {isModerator && (
                                <Tooltip
                                    content="Dodaj nowy dokument"
                                    showDelay={500}
                                    position="right"
                                >
                                    <button
                                        type="button"
                                        className="button"
                                        onClick={onSendInvoiceClick}
                                    >
                                        <FaFileUpload size="18" color="#142c66" />
                                        Nowy dokument
                                    </button>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserExpandedRow
