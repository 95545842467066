import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Table } from 'evergreen-ui'
import { useEffect, useState } from 'react'
import TopBar from '../../components/TopBar/TopBar'
import LeftMenu from '../../components/LeftMenu'
import ActiveFilters from '../../components/activeFilters'
import PaginationInput from '../../components/PaginationInput'
import RowLimit from '../../components/RowLimit'
import PaginationButtons from '../../components/PaginationButtons'
import Footer from '../../components/Footer'
import LoadingRows from '../../components/ListRows/LoadingRows'
import NoResultsRow from '../../components/ListRows/NoResultsRow'
import useApi from '../../hooks/useApi'
import getPathArguments from '../../utils/pathIDs'
import { fromMeterApi } from '../../utils/Adapters/MeterApiAdapter'
import { addMeters } from '../../redux/slices/meterSlice'
import { fromAlertApi } from '../../utils/Adapters/AlertApiAdapter'
import AlertRow from '../../components/ListRows/AlertRow'
import { addUsers } from '../../redux/slices/userSlice'
import fromGetUserTenantApi from '../../utils/Adapters/UserApiAdapter'
import { addAlerts } from '../../redux/slices/alertsSlice'

const AlertTableRows = ({ alerts, meters, fetchStatus }) => {
    if (!fetchStatus) {
        return <LoadingRows elements={alerts} />
    }
    if (Object.values(alerts).length === 0) {
        return <NoResultsRow />
    }

    return Object.entries(alerts)
        .sort((a, b) => a[1].index - b[1].index)
        .map(([key, value]) => {
            return <AlertRow key={key} alert={value} meter={meters[value.meter]} />
        })
}

const AlertsModerator = () => {
    const dispatch = useDispatch()

    const [urlParams, setUrlParams] = useSearchParams()

    const meters = useSelector((state) => state.meter.meters)
    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const alerts = useSelector((state) => state.alert.alerts)
    const alertsNextPage = useSelector((state) => state.alert.nextPage)
    const alertsPreviousPage = useSelector((state) => state.alert.previousPage)
    const alertsResultsCount = useSelector((state) => state.alert.count)
    const rowLimit = useSelector((state) => state.profile.rowLimit)

    const [offset, setOffset] = useState(0)
    const [pageCount, setPageCount] = useState(1)

    // url params - filters
    const [userParam, setUserParam] = useState(null)
    const [meterParam, setMeterParam] = useState(null)
    const [measurementParam, setMeasurementParam] = useState(null)
    const [stateParam, setStateParam] = useState(null)
    const [startDateParam, setStartDateParam] = useState(null)
    const [endDateParam, setEndDateParam] = useState(null)
    const [measurementListParam, setMeasurementListParam] = useState(null)

    // url params - sorting
    const [sortingParam, setSortingParam] = useState(null)

    const buildURL = (limit) => {
        let baseURL = `/app/alerts/moderator/alerts/?limit=${limit}&offset=${offset}`

        // filters
        if (userParam !== null) baseURL += `&meter__tenant_member=${userParam}`
        if (meterParam !== null) baseURL += `&meter=${meterParam}`
        if (measurementParam !== null) baseURL += `&id__in=${measurementParam}`
        if (stateParam !== null) baseURL += `&state=${stateParam}`
        if (startDateParam !== null && endDateParam !== null) {
            baseURL += `&consumption_ts_after=${startDateParam}`
            baseURL += `&consumption_ts_before=${endDateParam}`
        }
        if (measurementListParam !== null) baseURL += `&id__in=${measurementListParam}`

        // sorting
        if (sortingParam !== null) baseURL += `&ordering=${sortingParam}`

        return baseURL
    }

    const fetchAlerts = useApi({
        url: buildURL(rowLimit),
        method: 'GET',
        apiKey,
        fromApiAdapter: fromAlertApi,
        queryName: [
            'alerts',
            userParam,
            meterParam,
            measurementParam,
            measurementListParam,
            stateParam,
            sortingParam,
            startDateParam,
            endDateParam,
            offset,
            rowLimit
        ],
        onSuccess: (data) => {
            dispatch(addAlerts(data))
        }
    })

    const fetchMetersByID = useApi({
        url: `/app/meters/moderator/meter/?id__in=${getPathArguments(alerts, 'meter')}`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeterApi,
        queryName: ['metersByID', Object.keys(alerts)],
        enabled: fetchAlerts.isSuccess,
        onSuccess: (data) => {
            dispatch(addMeters(data))
        },
        keepPreviousData: true
    })

    useApi({
        url: `/app/tenants/moderator/tenant_member/?id__in=${getPathArguments(
            meters,
            'tenantMember'
        )}`,
        method: 'GET',
        apiKey,
        enabled: fetchMetersByID.isSuccess,
        fromApiAdapter: fromGetUserTenantApi,
        queryName: ['users', Object.keys(meters)],
        onSuccess: (data) => dispatch(addUsers(data))
    })

    const onPageClick = (page, direction) => {
        if (page !== null) {
            if (direction === 1) setPageCount((old) => old + 1)
            else setPageCount((old) => Math.max(old - 1, 0))
            const offsetPosition = page.indexOf('offset')
            if (offsetPosition !== -1) setOffset(page.substring(offsetPosition + 7, page.length))
            else setOffset(0)
        }
    }

    const handlePaginationInputonBlur = (e) => {
        setOffset(rowLimit * e - rowLimit)
        setPageCount(e)
    }

    const handleOnRemoveFilterClick = () => {
        if (!Number.isNaN(meterParam)) setUserParam(null)
        if (!Number.isNaN(meterParam)) setMeterParam(null)
    }

    useEffect(() => {
        if (!urlParams.has('consumption_range')) {
            urlParams.append('consumption_range', 'day')
            setUrlParams(urlParams)
        }

        return () => {
            setMeterParam('')
            setUserParam('')
        }
    }, [])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }
        setUserParam(null)
        setMeterParam(null)
        setMeasurementParam(null)
        setStateParam(null)
        setStartDateParam(null)
        setEndDateParam(null)
        setSortingParam(null)
        setMeasurementListParam(null)

        // filters
        const userParamGET = parseInt(urlParams.get('user_id'), 10)
        const meterParamGET = parseInt(urlParams.get('meter_id'), 10)
        const measurementParamGET = parseInt(urlParams.get('reading_id'), 10)
        const setStateParamGET = urlParams.get('state') || null
        const createdAtParamGET = urlParams.get('created_at') || null
        const consumptionTsParamGET = urlParams.get('consumption_ts') || null
        const consumptionRangeParamGET = urlParams.get('consumption_range') || 'day'
        const consumptionTsAfterParamGET = urlParams.get('consumption_ts_after') || null
        const consumptionTsBeforeParamGET = urlParams.get('consumption_ts_before') || null
        const measurementListParamGET = urlParams.get('reading_ids')

        // sorting
        const sortingParamGET = urlParams.get('sorting') || null

        // filters
        if (!Number.isNaN(userParamGET)) setUserParam(userParamGET)
        if (!Number.isNaN(meterParamGET)) setMeterParam(meterParamGET)
        if (!Number.isNaN(measurementParamGET)) setMeasurementParam(measurementParamGET)
        if (measurementListParamGET !== null) setMeasurementListParam(measurementListParamGET)
        if (setStateParamGET !== null) setStateParam(setStateParamGET)
        if (consumptionTsAfterParamGET !== null) setStartDateParam(consumptionTsAfterParamGET)
        if (consumptionTsBeforeParamGET !== null) setEndDateParam(consumptionTsBeforeParamGET)

        // sorting
        if (sortingParamGET !== null) {
            switch (sortingParamGET) {
                case 'created_at_asc':
                    setSortingParam('created_at')
                    break
                case 'created_at_desc':
                    setSortingParam('-created_at')
                    break
                case 'updated_at_asc':
                    setSortingParam('updated_at')
                    break
                case 'updated_at_desc':
                    setSortingParam('-updated_at')
                    break
                default:
                    break
            }
        }
    }, [urlParams])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }
    }, [rowLimit])

    return (
        <div className="main">
            <TopBar />
            <LeftMenu active="alerts" />
            <div className="main_content">
                <div className="title">
                    <div className="title_name">Alerty</div>
                    {/* <ActiveFilters onRemoveClick={handleOnRemoveFilterClick} alertsFilters /> */}
                </div>

                <div className="container">
                    <div className="container_main">
                        <div className="container_cards">
                            <Table className="table">
                                <Table.Head className="header">
                                    <Table.TextHeaderCell className="table_title_col">
                                        Typ alertu
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Nazwa odbiorcy
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        ID odbiorcy
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Typ licznika
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Numer licznika
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell
                                        className="table_title_col"
                                        flexBasis="300px"
                                        flexShrink={0}
                                        flexGrow={0}
                                    >
                                        Opis alertu
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell
                                        className="table_title_col"
                                        flexBasis="150px"
                                        flexShrink={0}
                                        flexGrow={0}
                                    >
                                        Działania
                                    </Table.TextHeaderCell>
                                </Table.Head>
                                <Table.Body>
                                    <AlertTableRows
                                        alerts={alerts}
                                        meters={meters}
                                        fetchStatus={fetchMetersByID.isSuccess}
                                    />
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="pagination_box">
                            <PaginationInput
                                pageCount={pageCount}
                                onBlur={handlePaginationInputonBlur}
                                resultCount={alertsResultsCount}
                                apiLimit={rowLimit}
                            />
                            <RowLimit />
                        </div>
                        <PaginationButtons
                            pageCount={pageCount}
                            onPageClick={onPageClick}
                            prevPage={alertsPreviousPage}
                            nextPage={alertsNextPage}
                            resultCount={alertsResultsCount}
                            apiLimit={rowLimit}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AlertsModerator
