import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { IoWarningOutline } from 'react-icons/io5'
import { Table, toaster, Dialog } from 'evergreen-ui'
import 'react-medium-image-zoom/dist/styles.css'

import RowLimit from '../../components/RowLimit'
import ActiveFilters from '../../components/activeFilters'
import TopBar from '../../components/TopBar/TopBar'
import LeftMenu from '../../components/LeftMenu'
import AddPdfDialog from '../../components/PdfDialog'
import PaginationButtons from '../../components/PaginationButtons'
import LoadingRows from '../../components/ListRows/LoadingRows'
import MeasurementRow from '../../components/ListRows/MeasurementRow'
import Footer from '../../components/Footer'
import NoResultsRow from '../../components/ListRows/NoResultsRow'

import useApi from '../../hooks/useApi'

import {
    verifyMeasurement,
    addMeasurements,
    bulkVerifyMeasurement
} from '../../redux/slices/measurementSlice'
import Measurement from '../../redux/models/Measurement'
import { addMeters } from '../../redux/slices/meterSlice'
import { addUsers } from '../../redux/slices/userSlice'

import {
    fromMeasurementApi,
    fromVerifyMeasurementApi,
    toMeasurementInvoiceApi,
    toVerifyMeasurementeApi,
    fromBulkVerifyMeasurementApi,
    toBulkVerifyMeasurementApi,
    toRejectMeasurementeApi
} from '../../utils/Adapters/MeasurementApiAdapter'
import { fromMeterApi } from '../../utils/Adapters/MeterApiAdapter'
import { fromGetUserTenantApi } from '../../utils/Adapters/UserApiAdapter'
import getPathArguments from '../../utils/pathIDs'
import PaginationInput from '../../components/PaginationInput'
import MeasurementVerifyDialog from '../../components/MeasurementsDialogs/MeasurementVerifyDialog'
import MeasurementDeclineDialog from '../../components/MeasurementsDialogs/MeasurementDeclineDialog'
import { measurementRejectOptions } from '../../static/rejectOptions'
import MeasurementAcceptDialog from '../../components/MeasurementsDialogs/MeasurementAcceptDialog'
import eventEmitter from '../../utils/eventEmitter'

const MeasurementTableRows = ({
    measurements,
    meters,
    onConfirmMeasurement,
    fetchStatus,
    onRowSelected,
    isSelectedEveryRow,
    isSelectedEveryRowIndeterminate
}) => {
    if (!fetchStatus) {
        return <LoadingRows elements={measurements} />
    }
    if (Object.values(measurements).length === 0) {
        return <NoResultsRow />
    }

    return Object.entries(measurements)
        .sort((a, b) => a[1].index - b[1].index)
        .map(([key, value]) => {
            return (
                <MeasurementRow
                    key={key}
                    measurement={value}
                    meter={meters[value.meter]}
                    onConfirmMeasurement={onConfirmMeasurement}
                    onRowSelected={onRowSelected}
                    isSelectedEveryRow={isSelectedEveryRow}
                    isSelectedEveryRowIndeterminate={isSelectedEveryRowIndeterminate}
                />
            )
        })
}

const MeasurementsUser = () => {
    const dispatch = useDispatch()

    const [urlParams, setUrlParams] = useSearchParams()

    const meters = useSelector((state) => state.meter.meters)
    const measurements = useSelector((state) => state.measurement.measurements)
    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const measurementNextPage = useSelector((state) => state.measurement.nextPage)
    const measurementPreviousPage = useSelector((state) => state.measurement.previousPage)
    const measurementResultsCount = useSelector((state) => state.measurement.count)
    const rowLimit = useSelector((state) => state.profile.rowLimit)

    const [selectedMeasurement, setSelecteMeasurement] = useState(new Measurement())
    const [isShown, setIsShown] = useState(false)
    const [loadedPdfFile, setLoadedPdfFile] = useState({})
    const [isImageShown, setIsImageShown] = useState(false)
    const [imageUrl, setImageUrl] = useState('')
    const [offset, setOffset] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [rejectTitle, setRejectTitle] = useState('')
    const [rejectContent, setRejectContent] = useState('')
    const [isMeasurementDecilneShown, setIsMeasurementDecilneShown] = useState(false)
    const [isMeasurementAcceptShown, setIsMeasurementAcceptShown] = useState(false)

    // mass actions
    const [selectedRows, setSelectedRows] = useState([])
    const [isSelectedEveryRow, setIsSelectedEveryRow] = useState(false)
    const [isSelectedEveryRowIndeterminate, setIsSelectedEveryRowIndeterminate] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [massActionObject, setMassActionObject] = useState(null)

    // url params - filters
    const [userParam, setUserParam] = useState(null)
    const [meterParam, setMeterParam] = useState(null)
    const [measurementParam, setMeasurementParam] = useState(null)
    const [stateParam, setStateParam] = useState(null)
    const [startDateParam, setStartDateParam] = useState(null)
    const [endDateParam, setEndDateParam] = useState(null)
    const [measurementListParam, setMeasurementListParam] = useState(null)

    // url params - sorting
    const [sortingParam, setSortingParam] = useState(null)

    const buildURL = () => {
        let baseURL = `/app/meters/iot/measurement/?limit=${rowLimit}&offset=${offset}`

        // filters
        if (userParam !== null) baseURL += `&meter__tenant_member=${userParam}`
        if (meterParam !== null) baseURL += `&meter=${meterParam}`
        if (measurementParam !== null) baseURL += `&id__in=${measurementParam}`
        if (stateParam !== null) baseURL += `&state=${stateParam}`
        if (startDateParam !== null && endDateParam !== null) {
            baseURL += `&created_at_after=${startDateParam}`
            baseURL += `&created_at_before=${endDateParam}`
        }
        if (measurementListParam !== null) baseURL += `&id__in=${measurementListParam}`

        // sorting
        if (sortingParam !== null) baseURL += `&ordering=${sortingParam}`

        return baseURL
    }

    const fetchMeasurements = useApi({
        url: buildURL(),
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeasurementApi,
        queryName: [
            'measurement',
            userParam,
            meterParam,
            measurementParam,
            measurementListParam,
            stateParam,
            sortingParam,
            startDateParam,
            endDateParam,
            offset,
            rowLimit
        ],
        onSuccess: (data) => dispatch(addMeasurements(data))
    })

    const fetchMetersByID = useApi({
        url: `/app/meters/meter/?id__in=${getPathArguments(measurements, 'meter')}`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeterApi,
        queryName: ['metersByID', Object.keys(measurements)],
        enabled: fetchMeasurements.isSuccess,
        onSuccess: (data) => {
            dispatch(addMeters(data))
        },
        keepPreviousData: true
    })

    const fetchUsersByID = useApi({
        url: `/app/tenants/standard/tenant_member/?id__in=${getPathArguments(
            meters,
            'tenantMember'
        )}`,
        method: 'GET',
        apiKey,
        enabled: fetchMetersByID.isSuccess,
        fromApiAdapter: fromGetUserTenantApi,
        queryName: ['users', Object.keys(meters)],
        onSuccess: (data) => dispatch(addUsers(data))
    })

    const verifyMeasurementCall = useApi({
        method: 'PUT',
        apiKey,
        toApiAdapter: toVerifyMeasurementeApi
    })
    const rejectMeasurementCall = useApi({
        method: 'PATCH',
        apiKey,
        toApiAdapter: toRejectMeasurementeApi
    })

    const sendInvoiceCall = useApi({
        method: 'POST',
        apiKey,
        isFile: true,
        url: '/app/meters/moderator/invoice/',
        toApiAdapter: toMeasurementInvoiceApi
    })

    const bulkUpdateMeasurements = useApi({
        url: '/app/meters/moderator/iot/measurement/bulk_update/',
        method: 'POST',
        apiKey,
        toApiAdapter: toBulkVerifyMeasurementApi,
        fromApiAdapter: fromBulkVerifyMeasurementApi,
        queryName: ['bulk_update', selectedRows]
    })

    const doesRowsContainDifferentStates = () => {
        const statesList = selectedRows.map((id) => measurements[id].isChecked)
        if (statesList.includes(true) && statesList.includes(false)) return true
        return false
    }

    const onPageClick = (page, direction) => {
        if (page !== null) {
            if (direction === 1) setPageCount((old) => old + 1)
            else setPageCount((old) => Math.max(old - 1, 0))
            const offsetPosition = page.indexOf('offset')
            if (offsetPosition !== -1) setOffset(page.substring(offsetPosition + 7, page.length))
            else setOffset(0)
        }
    }

    const handleOnRemoveFilterClick = () => {
        if (!Number.isNaN(meterParam)) setUserParam(null)
        if (!Number.isNaN(meterParam)) setMeterParam(null)
    }

    const handleOnSendClick = () => {
        if (selectedMeasurement.id !== null) {
            sendInvoiceCall
                .mutateAsync({
                    data: {
                        file: loadedPdfFile,
                        measurement: selectedMeasurement.id
                    }
                })
                .then(() => {
                    toaster.success('Pomyślnie dodano fakturę')
                })
        } else {
            toaster.warning('Najpierw wybierz pomiar')
        }
    }

    const onConfirmMeasurementButtonClick = async (measurement) => {
        setImageUrl(measurement.image)
        setIsImageShown(true)
        setSelecteMeasurement(measurement)
    }

    const onSendMeasurementButtonClick = async () => {
        setIsImageShown(false)
        verifyMeasurementCall
            .mutateAsync({
                data: {
                    value: selectedMeasurement.value,
                    state: 'verified'
                },
                url: `/app/meters/moderator/iot/measurement/${selectedMeasurement.id}/`
            })
            .then((response) => {
                dispatch(verifyMeasurement(fromVerifyMeasurementApi(response)))
                toaster.success('Zweryfikowano pomyslnie')
            })
    }

    const handleOnSendRejectionClick = () => {
        if (rejectContent.length > 0) {
            rejectMeasurementCall
                .mutateAsync({
                    data: {
                        state: 'rejected',
                        reason: rejectContent
                    },
                    url: `/app/meters/moderator/iot/measurement/${selectedMeasurement.id}/`
                })
                .then((response) => {
                    setIsImageShown(false)
                    dispatch(verifyMeasurement(fromVerifyMeasurementApi(response)))
                    toaster.success('Pomiar został odrzucony')
                })
        } else {
            toaster.warning('Wybierz powód odrzucenia zgłoszenia')
        }
    }

    const handleRowSelectChanged = (pk) => {
        if (selectedRows.indexOf(pk) !== -1)
            setSelectedRows((current) => current.filter((elem) => elem !== pk))
        else setSelectedRows((current) => [...current, pk])
    }

    const handeSelectEveryRowClick = () => {
        if (isSelectedEveryRowIndeterminate) {
            setSelectedRows([])
            setIsSelectedEveryRowIndeterminate(false)
            return
        }

        if (isSelectedEveryRow) setSelectedRows([])
        else {
            const ids = Object.values(measurements).map((measurement) => measurement.id)
            setSelectedRows(ids)
        }
        setIsSelectedEveryRow((current) => !current)
    }

    const handleMassActionClick = (actionObject) => {
        if (selectedRows.length === 0) {
            toaster.notify('Nie wybrano żadnego wiersza')
            return
        }

        setMassActionObject(actionObject)
        setIsDialogOpen(true)
    }

    const handleMassActionCancel = () => {
        setMassActionObject(null)
        setIsDialogOpen(false)
    }

    const handleTopicChanged = (e) => {
        measurementRejectOptions.forEach((topic) => {
            if (topic.title === e) {
                setRejectTitle(topic.title)
                setRejectContent(topic.content)
            }
        })
    }

    const handleMassActionConfirm = () => {
        setIsDialogOpen(false)

        switch (massActionObject.value) {
            case 'verify': {
                const data = {
                    ids: selectedRows,
                    state: 'verified'
                }
                bulkUpdateMeasurements.mutateAsync({ data }).then((response) => {
                    toaster.success('Pomyslnie zatwierdzono pomiary')
                    dispatch(bulkVerifyMeasurement(data))
                })
                setSelectedRows([])
                break
            }
            default:
                break
        }
    }

    const handlePaginationInputonBlur = (e) => {
        setOffset(rowLimit * e - rowLimit)
        setPageCount(e)
    }

    useEffect(() => {
        eventEmitter.on('reload', () => {
            fetchMeasurements.refetch()
        })

        return () => {
            eventEmitter.off('reload')
        }
    }, [])

    // logika zaznaczania wierszy
    useEffect(() => {
        if (Object.values(selectedRows).length === 0) {
            setIsSelectedEveryRow(false)
            setIsSelectedEveryRowIndeterminate(false)
        } else if (selectedRows.length === Object.values(measurements).length) {
            setIsSelectedEveryRow(true)
            setIsSelectedEveryRowIndeterminate(false)
        } else {
            setIsSelectedEveryRow(false)
            setIsSelectedEveryRowIndeterminate(true)
        }
    }, [selectedRows])

    useEffect(() => {
        return () => {
            setSelecteMeasurement(new Measurement())
            setMeterParam('')
            setUserParam('')
        }
    }, [])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }
        setUserParam(null)
        setMeterParam(null)
        setMeasurementParam(null)
        setStateParam(null)
        setStartDateParam(null)
        setEndDateParam(null)
        setSortingParam(null)
        setMeasurementListParam(null)

        // filters
        const userParamGET = parseInt(urlParams.get('user_id'), 10)
        const meterParamGET = parseInt(urlParams.get('meter_id'), 10)
        const measurementParamGET = parseInt(urlParams.get('reading_id'), 10)
        const setStateParamGET = urlParams.get('state') || null
        const createdAtParamGET = urlParams.get('created_at') || null
        const measurementListParamGET = urlParams.get('reading_ids')

        // sorting
        const sortingParamGET = urlParams.get('sorting') || null

        // filters
        if (!Number.isNaN(userParamGET)) setUserParam(userParamGET)
        if (!Number.isNaN(meterParamGET)) setMeterParam(meterParamGET)
        if (!Number.isNaN(measurementParamGET)) setMeasurementParam(measurementParamGET)
        if (measurementListParamGET !== null) setMeasurementListParam(measurementListParamGET)
        if (setStateParamGET !== null) setStateParam(setStateParamGET)
        if (createdAtParamGET !== null) {
            const endDate = new Date()
            let startDate = null
            setEndDateParam(endDate.toISOString().split('T')[0])

            // 1000 * 60 * 60 * 24 - stands for one day in ms
            switch (createdAtParamGET) {
                case 'last_24h': {
                    startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_week': {
                    startDate = new Date(endDate.getTime() - 7 * 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_month': {
                    startDate = new Date(endDate.getTime() - 30 * 1000 * 60 * 60 * 24)
                    break
                }
                default:
                    break
            }

            setStartDateParam(startDate.toISOString().split('T')[0])
        }

        // sorting
        if (sortingParamGET !== null) {
            switch (sortingParamGET) {
                case 'created_at_asc':
                    setSortingParam('created_at')
                    break
                case 'created_at_desc':
                    setSortingParam('-created_at')
                    break
                case 'updated_at_asc':
                    setSortingParam('updated_at')
                    break
                case 'updated_at_desc':
                    setSortingParam('-updated_at')
                    break
                default:
                    setSortingParam(sortingParamGET)
                    break
            }
        }
    }, [urlParams])

    useEffect(() => {
        setSelecteMeasurement(Object.values(measurements)[0])
    }, [measurements])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }
    }, [rowLimit])

    return (
        <div className="main">
            <TopBar />
            <LeftMenu active="measurements" />
            <div className="main_content">
                <div className="title">
                    <div className="title_name">Odczyty</div>
                    <ActiveFilters
                        onRemoveClick={handleOnRemoveFilterClick}
                        onMassActionSelect={handleMassActionClick}
                        measurementFilters
                    />
                </div>

                <div className="container">
                    <div className="container_main">
                        <div className="container_cards">
                            <Table className="table">
                                <Table.Head className="header">
                                    <Table.TextHeaderCell className="table_title_col">
                                        Nazwa odbiorcy
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Typ licznika
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Nazwa licznika
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Wartość odczytu
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Data odczytu
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Status
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell
                                        className="table_title_col"
                                        flexBasis="180px"
                                        flexShrink={0}
                                        flexGrow={0}
                                    >
                                        Działania
                                    </Table.TextHeaderCell>
                                </Table.Head>
                                <Table.Body>
                                    <MeasurementTableRows
                                        measurements={measurements}
                                        meters={meters}
                                        setSelecteMeasurement={setSelecteMeasurement}
                                        selectedMeasurement={selectedMeasurement}
                                        onConfirmMeasurement={onConfirmMeasurementButtonClick}
                                        fetchStatus={fetchUsersByID.isSuccess}
                                        onRowSelected={handleRowSelectChanged}
                                        isSelectedEveryRow={isSelectedEveryRow}
                                        isSelectedEveryRowIndeterminate={
                                            isSelectedEveryRowIndeterminate
                                        }
                                    />
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="pagination_box">
                            <PaginationInput
                                pageCount={pageCount}
                                onBlur={handlePaginationInputonBlur}
                                resultCount={measurementResultsCount}
                                apiLimit={rowLimit}
                            />
                            <RowLimit />
                        </div>
                        <PaginationButtons
                            pageCount={pageCount}
                            onPageClick={onPageClick}
                            prevPage={measurementPreviousPage}
                            nextPage={measurementNextPage}
                            resultCount={measurementResultsCount}
                            apiLimit={rowLimit}
                        />
                    </div>
                </div>
            </div>
            <AddPdfDialog
                isShown={isShown}
                setIsShown={setIsShown}
                loadedFile={setLoadedPdfFile}
                onSendClick={handleOnSendClick}
                selectedMeasurementPk={selectedMeasurement?.id}
            />
            <MeasurementVerifyDialog
                isShown={isImageShown}
                setIsImageShown={setIsImageShown}
                imageUrl={imageUrl}
                selectedMeasurement={selectedMeasurement}
                onSendMeasurementButtonClick={onSendMeasurementButtonClick}
                setIsRejectMeasurementShown={setIsMeasurementDecilneShown}
                setIsAcceptMeasurementShown={setIsMeasurementAcceptShown}
            />
            <MeasurementAcceptDialog
                isShown={isMeasurementAcceptShown}
                setIsShown={setIsMeasurementAcceptShown}
                onSendClick={onSendMeasurementButtonClick}
            />
            <MeasurementDeclineDialog
                isShown={isMeasurementDecilneShown}
                setIsShown={setIsMeasurementDecilneShown}
                onSendClick={handleOnSendRejectionClick}
                handleTopicChanged={handleTopicChanged}
                topics={measurementRejectOptions}
                setRejectContent={setRejectContent}
                rejectContent={rejectContent}
                rejectTitle={rejectTitle}
            />
            <Footer />
            <Dialog
                isShown={isDialogOpen}
                title="No footer"
                onCloseComplete={() => setIsDialogOpen(false)}
                onConfirm={handleMassActionConfirm}
                onCancel={handleMassActionCancel}
                hasHeader={false}
                confirmLabel="Potwierdź"
                cancelLabel="Anuluj"
            >
                <div className="dialog_box_container">
                    <span className="title">
                        To działanie spowoduje wykonanie wybranej akcji na wszystkich zaznaczonych
                        wierszach. Czy na pewno chcesz kontynuować?
                    </span>
                    {doesRowsContainDifferentStates() && (
                        <div className="different_states_warning_container">
                            <div className="different_states_warning_icon">
                                <IoWarningOutline size={40} />
                            </div>
                            <div className="different_states_warning_text">
                                Zaznaczone wiersze posiadają różne stany weryfikacji. Sprawdź
                                poprawność zaznaczonych danych.
                            </div>
                        </div>
                    )}
                </div>
            </Dialog>
        </div>
    )
}

export default MeasurementsUser
