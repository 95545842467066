export const optionChoices = {
    // user filters
    userStateOptions: [
        { label: 'Nowy', value: 'new', filter: 'state' },
        { label: 'Odrzucony', value: 'rejected', filter: 'state' },
        { label: 'Zweryfikowany', value: 'verified', filter: 'state' }
    ],
    userTypeOptions: [
        { label: 'Odbiorcy instytucjonalni', value: 'is_company_true', filter: 'is_company' },
        { label: 'Odbiorcy indywidualni', value: 'is_company_false', filter: 'is_company' }
    ],
    userCreatedAtOptions: [
        { label: 'Ostatnie 24h', value: 'last_24h', filter: 'created_at' },
        { label: 'Ostatni tydzień', value: 'last_week', filter: 'created_at' },
        { label: 'Ostatni miesiąc', value: 'last_month', filter: 'created_at' }
    ],
    userUpdatedAtOptions: [
        { label: 'Ostatnie 24h', value: 'last_24h', filter: 'updated_at' },
        { label: 'Ostatni tydzień', value: 'last_week', filter: 'updated_at' },
        { label: 'Ostatni miesiąc', value: 'last_month', filter: 'updated_at' }
    ],
    // meter filters
    meterTypeOptions: [
        { label: 'Woda zimna', value: 'cold_water', filter: 'meter_type' },
        { label: 'Woda ciepła', value: 'hot_water', filter: 'meter_type' },
        { label: 'Gaz', value: 'gas', filter: 'meter_type' },
        { label: 'Ciepło', value: 'heat', filter: 'meter_type' },
        { label: 'Elektryczność', value: 'electricity', filter: 'meter_type' }
    ],
    meterCreatedAtOptions: [
        { label: 'Ostatnie 24h', value: 'last_24h', filter: 'created_at' },
        { label: 'Ostatni tydzień', value: 'last_week', filter: 'created_at' },
        { label: 'Ostatni miesiąc', value: 'last_month', filter: 'created_at' }
    ],
    meterUpdatedAtOptions: [
        { label: 'Ostatnie 24h', value: 'last_24h', filter: 'updated_at' },
        { label: 'Ostatni tydzień', value: 'last_week', filter: 'updated_at' },
        { label: 'Ostatni miesiąc', value: 'last_month', filter: 'updated_at' }
        // TODO: check backend for this option
        // { label: 'Brak odczytów', value: 'empty', filter: 'updated_at' }
    ],
    // measurement filters
    measurementStateOptions: [
        { label: 'Nowy', value: 'new', filter: 'state' },
        { label: 'Odrzucony', value: 'rejected', filter: 'state' },
        { label: 'Zweryfikowany', value: 'verified', filter: 'state' }
    ],
    measurementCreatedAtOptions: [
        { label: 'Ostatnie 24h', value: 'last_24h', filter: 'created_at' },
        { label: 'Ostatni tydzień', value: 'last_week', filter: 'created_at' },
        { label: 'Ostatni miesiąc', value: 'last_month', filter: 'created_at' }
    ],
    measurementTypeOptions: [
        { label: 'Woda zimna', value: 'cold_water', filter: 'meter_type' },
        { label: 'Woda ciepła', value: 'hot_water', filter: 'meter_type' },
        { label: 'Gaz', value: 'gas', filter: 'meter_type' },
        { label: 'Ciepło', value: 'heat', filter: 'meter_type' },
        { label: 'Elektryczność', value: 'electricity', filter: 'meter_type' }
    ],
    // consumption filters
    consumptionTimeRangeOptions: [
        { label: 'Dzień', value: 'day', filter: 'consumption_range' },
        { label: 'Miesiąc', value: 'month', filter: 'consumption_range' }
    ],
    // documents filters
    documentCreatedAtOptions: [
        { label: 'Ostatnie 24h', value: 'last_24h', filter: 'created_at' },
        { label: 'Ostatni tydzień', value: 'last_week', filter: 'created_at' },
        { label: 'Ostatni miesiąc', value: 'last_month', filter: 'created_at' }
    ],
    documentPaymentsOptions: [
        { label: 'Należności rozliczone', value: 'is_paid_true', filter: 'payment_status' },
        { label: 'Należności nierozliczone', value: 'is_paid_false', filter: 'payment_status' }
    ],
    // notifications filters
    notificationCreatedAtOptions: [
        { label: 'Ostatnie 24h', value: 'last_24h', filter: 'created_at' },
        { label: 'Ostatni tydzień', value: 'last_week', filter: 'created_at' },
        { label: 'Ostatni miesiąc', value: 'last_month', filter: 'created_at' }
    ],
    notificationIsAutomaticOptions: [
        { label: 'Tak', value: 'true', filter: 'is_automatic' },
        { label: 'Nie', value: 'false', filter: 'is_automatic' }
    ],
    notificationChannelOptions: [
        { label: 'Mobilne', value: 'push', filter: 'channel' },
        { label: 'E-mail', value: 'email', filter: 'channel' },
        { label: 'SMS', value: 'sms', filter: 'channel' }
    ],
    notificationRecipientOptions: [
        { label: 'Wybrani odbiorcy', value: 'users', filter: 'recipient' },
        {
            label: 'Wszyscy odbiorcy indywidualni',
            value: 'individual_clients',
            filter: 'recipient'
        },
        { label: 'Wszyscy odbiorcy instytucjonalni', value: 'companies', filter: 'recipient' },
        { label: 'Wszyscy odbiorcy', value: 'all', filter: 'recipient' }
    ],
    // history filters
    historyCreatedAtOptions: [
        { label: 'Ostatnie 24h', value: 'last_24h', filter: 'created_at' },
        { label: 'Ostatni tydzień', value: 'last_week', filter: 'created_at' },
        { label: 'Ostatni miesiąc', value: 'last_month', filter: 'created_at' }
    ],
    historyExcludeOptions: [
        {
            label: 'Wyklucz odczyty',
            value: 'exclude_measurement',
            filter: 'exclude'
        },
        {
            label: 'Wyklucz odbiorców',
            value: 'exclude_tenant_member',
            filter: 'exclude'
        },
        {
            label: 'Wyklucz powiadomienia',
            value: 'exclude_notifications',
            filter: 'exclude'
        }
    ],
    // sorting
    userSortOptions: [
        { label: 'Domyślne', value: 'default', sorting: 'sorting' },
        {
            label: 'Data rejestracji (od najstarszych)',
            value: 'created_at_asc',
            sorting: 'sorting'
        },
        {
            label: 'Data rejestracji (od najnowszych)',
            value: 'created_at_desc',
            sorting: 'sorting'
        },
        {
            label: 'Data modyfikacji (od najstarszych)',
            value: 'updated_at_asc',
            sorting: 'sorting'
        },
        {
            label: 'Data modyfikacji (od najnowszych)',
            value: 'updated_at_desc',
            sorting: 'sorting'
        }
    ],
    meterSortOptions: [
        { label: 'Domyślne', value: 'default', sorting: 'sorting' },
        { label: 'Data utworzenia (od najstarszych)', value: 'created_at_asc', sorting: 'sorting' },
        { label: 'Data utworzenia (od najnowszych)', value: 'created_at_desc', sorting: 'sorting' },
        { label: 'Typ (rosnąco)', value: 'type', sorting: 'sorting' },
        { label: 'Typ (malejąco)', value: '-type', sorting: 'sorting' },
        { label: 'Numer licznika (rosnąco)', value: 'serial_number', sorting: 'sorting' },
        { label: 'Numer licznika (malejąco)', value: '-serial_number', sorting: 'sorting' },
        { label: 'Numer nakładki (rosnąco)', value: 'radio_id', sorting: 'sorting' },
        { label: 'Numer nakładki (malejąco)', value: '-radio_id', sorting: 'sorting' }
    ],
    measuerementSortOptions: [
        { label: 'Domyślne', value: 'default', sorting: 'sorting' },
        { label: 'Data utworzenia (od najstarszych)', value: 'created_at_asc', sorting: 'sorting' },
        { label: 'Data utworzenia (od najnowszych)', value: 'created_at_desc', sorting: 'sorting' },
        {
            label: 'Data modyfikacji (od najstarszych)',
            value: 'updated_at_asc',
            sorting: 'sorting'
        },
        {
            label: 'Data modyfikacji (od najnowszych)',
            value: 'updated_at_desc',
            sorting: 'sorting'
        },
        { label: 'Typ (rosnąco)', value: 'media', sorting: 'sorting' },
        { label: 'Typ (malejąco)', value: '-media', sorting: 'sorting' },
        { label: 'Wartość (rosnąco)', value: 'value', sorting: 'sorting' },
        { label: 'Wartość (malejąco)', value: '-value', sorting: 'sorting' }
    ],
    consumptionSortOptions: [
        { label: 'Domyślne', value: 'default', sorting: 'sorting' },
        { label: 'Data utworzenia (od najstarszych)', value: 'created_at_asc', sorting: 'sorting' },
        { label: 'Data utworzenia (od najnowszych)', value: 'created_at_desc', sorting: 'sorting' },
        {
            label: 'Data modyfikacji (od najstarszych)',
            value: 'updated_at_asc',
            sorting: 'sorting'
        },
        {
            label: 'Data modyfikacji (od najnowszych)',
            value: 'updated_at_desc',
            sorting: 'sorting'
        },
        { label: 'Wartość (rosnąco)', value: 'consumption', sorting: 'sorting' },
        { label: 'Wartość (malejąco)', value: '-consumption', sorting: 'sorting' }
    ],
    documentSortOptions: [
        { label: 'Domyślne', value: 'default', sorting: 'sorting' },
        { label: 'Data utworzenia (od najstarszych)', value: 'created_at_asc', sorting: 'sorting' },
        { label: 'Data utworzenia (od najnowszych)', value: 'created_at_desc', sorting: 'sorting' }
    ],
    notificationSortOptions: [
        { label: 'Domyślne', value: 'default', sorting: 'sorting' },
        { label: 'Data utworzenia (od najstarszych)', value: 'created_at_asc', sorting: 'sorting' },
        { label: 'Data utworzenia (od najnowszych)', value: 'created_at_desc', sorting: 'sorting' }
    ],
    historySortOptions: [
        { label: 'Domyślne', value: 'default', sorting: 'sorting' },
        { label: 'Data utworzenia (od najstarszych)', value: 'created_at_asc', sorting: 'sorting' },
        { label: 'Data utworzenia (od najnowszych)', value: 'created_at_desc', sorting: 'sorting' }
    ],
    // mass actions
    userMassActionOptions: [{ label: 'Wystaw fakturę', value: 'invoice' }],
    meterMassActionOptions: [],
    measuerementMassActionOptions: [{ label: 'Zatwierdź odczyty', value: 'verify' }],
    documentMassActionOptions: [{ label: 'Podejrzyj faktury', value: 'preview' }]
}

export const optionValueChoices = {
    true: 'tak',
    false: 'nie',
    company: 'Odbiorca instytucjonalny',
    individual: 'Odbiorca indywidualny',
    cold_water: 'woda zimna',
    hot_water: 'woda ciepła',
    garden_water: 'woda ogrodowa',
    gas: 'gaz',
    heat: 'ciepło',
    electricity: 'elektryczność',
    other: 'inny',
    last_24h: 'ostatnie 24h',
    last_week: 'ostatni tydzień',
    last_month: 'ostatni miesiąc',
    individual_clients: 'Wszycy odbiorcy indywidualni',
    companies: 'Wszycy odbiorcy instytucjonalni',
    users: 'Wybrani odbiorcy',
    all: 'Wszyscy odbiorcy',
    new: 'Nowy',
    rejected: 'Odrzucony',
    verified: 'Zweryfikowany',
    is_company_true: 'Odbiorcy instytucjonalni',
    is_company_false: 'Odbiorcy indywidualni',
    exclude_measurement: 'Modyfikacja odczytu',
    exclude_tenant_member: 'Modyfikacja odbiorcy',
    exclude_notifications: 'Powiadomienie',
    is_paid_true: 'Należności rozliczone',
    is_paid_false: 'Należności nierozliczone',
    day: 'Dzień',
    month: 'Miesiąc'
}

export const filterNames = {
    user_id: 'ID Odbiorcy:',
    meter_id: 'ID licznika:',
    id: 'ID zasobu:',
    user_type: 'Typ konta:',
    user_verified: 'Czy zweryfikowany:',
    meter_type: 'Typ licznika:',
    search: 'Wyszukiwanie:',
    reading_id: 'ID odczytu:',
    is_checked: 'Czy zweryfikowano:',
    is_verified: 'Czy zweryfikowano:',
    created_at: 'Data utworzenia:',
    updated_at: 'Data modyfikacji:',
    is_company: 'Typ odbiorcy:',
    state: 'Stan weryfikacji:',
    measurement_id: 'ID odczytu:',
    exclude: 'Wykluczenie:',
    channel: 'Kanał:',
    is_automatic: 'Wysłane automatycznie:',
    recipient: 'Odbiorca:',
    payment_status: 'Status płatności:',
    consumption_range: 'Zakres czasowy: '
}
