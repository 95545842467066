import { useSelector } from 'react-redux'

import ConsumptionCommon from './Consumption.common.view'

const Consumption = () => {
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    return isModerator ? (
        <ConsumptionCommon userRole="moderator" />
    ) : (
        <ConsumptionCommon userRole="standard" />
    )
}

export default Consumption
