import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GoMute, GoUnmute } from 'react-icons/go'
import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi'
import { MdMarkEmailRead, MdMarkEmailUnread } from 'react-icons/md'
import { Pane, Table, Tooltip } from 'evergreen-ui'

import renderMeterIconType from '../../utils/renderMeterIconType'
import { renderState } from '../../utils/renderStateMeasurement'
import useApi from '../../hooks/useApi'
import { toAlertApi } from '../../utils/Adapters/AlertApiAdapter'

const AlertRow = ({ alert = {}, meter = {} }) => {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user.users[meter.tenantMember])
    const isModerator = useSelector((state) => state.profile.profile.isModerator)
    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const [isExpanded, setIsExpanded] = useState(false)

    const isPrivate = meter.tenantMember === null || alert.state === 'non_billed'

    const renderUserName = () => {
        if (isPrivate) return '-'
        if (user?.isCompany) return user?.companyName
        return user?.nameSurname
    }

    const onExpand = () => {
        setIsExpanded(!isExpanded)
    }

    const markAsRead = useApi({
        method: 'POST',
        apiKey,
        url: `/app/alerts/moderator/alerts/${alert.id}/mark_as_read/`,
        toApiAdapter: toAlertApi
    })

    const muteAlert = useApi({
        method: 'POST',
        apiKey,
        url: `/app/alerts/moderator/alerts/${alert.id}/mute_alert/`,
        toApiAdapter: toAlertApi
    })

    return (
        <>
            <Table.Row className={`row ${isExpanded ? 'in_color' : ''}`}>
                <Table.TextCell>
                    <div className="alert">
                        <div className="alert_circle" style={{ backgroundColor: alert.color }} />
                        {alert.level}
                    </div>
                </Table.TextCell>
                <Table.TextCell>
                    <Tooltip content="Przejdź do odbiorcy" showDelay={500}>
                        <Link to={`/users?user_id=${user?.pk}`}>{renderUserName()}</Link>
                    </Tooltip>
                </Table.TextCell>
                <Table.TextCell isNumber>
                    <span>{user?.externalId}</span>
                </Table.TextCell>
                <Table.TextCell isNumber>
                    <span className="meter_type">{renderMeterIconType(meter, 16)}</span>
                </Table.TextCell>
                {isModerator ? (
                    <Table.TextCell isNumber>{meter.name}</Table.TextCell>
                ) : (
                    <Table.TextCell>{meter.name}</Table.TextCell>
                )}
                <Table.TextCell flexBasis="300px" flexShrink={0} flexGrow={0}>
                    {alert.title}
                </Table.TextCell>
                {!isModerator && (
                    <Table.TextCell isNumber>
                        <span>{renderState(alert.state, 16)}</span>
                    </Table.TextCell>
                )}
                <Table.TextCell flexBasis="100px" flexShrink={0} flexGrow={0}>
                    <div className="button_container">
                        <Tooltip
                            content={alert.isMuted ? 'Anuluj wyciszenie' : 'Wycisz alert'}
                            showDelay={500}
                        >
                            <button
                                type="button"
                                className="button"
                                onClick={async () => {
                                    // TODO: Add confirmation dialog; Make more user-friendly reload
                                    // eslint-disable-next-line no-restricted-globals,no-alert
                                    if (confirm('Czy na pewno chcesz zmienić status alertu?')) {
                                        await muteAlert.mutateAsync({
                                            data: { isMuted: !alert.isMuted }
                                        })
                                        navigate(0)
                                    }
                                }}
                            >
                                {alert.isMuted ? <GoMute size="18" /> : <GoUnmute size="18" />}
                            </button>
                        </Tooltip>
                        <Tooltip
                            content={
                                alert.isRead
                                    ? 'Oznacz jako nieprzeczytane'
                                    : 'Oznacz jako przeczytane'
                            }
                            showDelay={500}
                        >
                            <button
                                type="button"
                                className="button"
                                onClick={async () => {
                                    // TODO: Add confirmation dialog; Make more user-friendly reload
                                    // eslint-disable-next-line no-restricted-globals,no-alert
                                    if (confirm('Czy na pewno chcesz zmienić status alertu?')) {
                                        await markAsRead.mutateAsync({
                                            data: { isRead: !alert.isRead }
                                        })
                                        navigate(0)
                                    }
                                }}
                            >
                                {alert.isRead ? (
                                    <MdMarkEmailRead size="18" />
                                ) : (
                                    <MdMarkEmailUnread size="18" />
                                )}
                            </button>
                        </Tooltip>
                    </div>
                </Table.TextCell>
                <Table.TextCell className="gap" />
                <Table.TextCell className="alone_row">
                    <Tooltip
                        content="Szczegóły"
                        showDelay={500}
                        key={!isExpanded ? 0 : 1}
                        position="top"
                    >
                        <button type="button" onClick={onExpand} className="clickable">
                            {isExpanded ? (
                                <BiUpArrowAlt size="20" className="icon_white" />
                            ) : (
                                <BiDownArrowAlt size="20" />
                            )}
                        </button>
                    </Tooltip>
                </Table.TextCell>

                <Pane>{/*    */}</Pane>
            </Table.Row>
            <div className={`more_info ${isExpanded ? 'expanded' : 'hidden'}`}>
                <div className="more_info_content">
                    <div className="data_grid">
                        <p id="title">Treść alertu</p>
                        <p>{alert.body}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AlertRow
