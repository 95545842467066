import { Button } from 'evergreen-ui'
import { unparse } from 'papaparse'

const ExportToCsv = ({ onExport }) => {
    const downloadCsv = (header, rows) => {
        const blob = new Blob([unparse([header, ...rows], { quotes: true })], {
            type: 'text/csv;charset=utf-8;'
        })

        const url = URL.createObjectURL(blob)
        const linkElement = document.createElement('a')
        linkElement.href = url
        linkElement.download = `export-${new Date().toLocaleDateString()}`
        document.body.appendChild(linkElement)
        linkElement.click()
        document.body.removeChild(linkElement)
    }

    return (
        <Button className="button" onClick={() => onExport(downloadCsv)}>
            Eksportuj do pliku CSV
        </Button>
    )
}

export default ExportToCsv
