import { useState, useEffect } from 'react'

import { useSelector } from 'react-redux'

import Zoom from 'react-medium-image-zoom'
import { Dialog, Pane, Pill } from 'evergreen-ui'

import useApi from '../../hooks/useApi'

import dateConvert from '../../utils/formatDate'
import { fromMeasurementLogApi } from '../../utils/Adapters/MeasurementApiAdapter'
import { getState } from '../../utils/renderState'

const MeasurementVerifyDialog = ({
    isShown,
    setIsImageShown,
    imageUrl,
    selectedMeasurement,
    setIsRejectMeasurementShown,
    setIsAcceptMeasurementShown
}) => {
    const [logs, setLogs] = useState(null)
    const [rejectReason, setRejectReason] = useState('')

    const apiKey = useSelector((state) => state.profile.profile.apiKey)

    useApi({
        url: `/app/meters/moderator/iot/measurement/${selectedMeasurement?.id}/logs/`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeasurementLogApi,
        enabled: selectedMeasurement?.state === 'rejected' && isShown,
        queryName: ['measurement_log', selectedMeasurement?.id],
        onSuccess: (data) => setLogs(data)
    })

    useEffect(() => {
        setRejectReason(logs?.results.at(-1)?.changes.find((elem) => elem.includes('reason'))?.[1])
    }, [logs])

    const handleOnRejectClick = () => {
        setIsRejectMeasurementShown((current) => !current)
    }

    const handleOnAcceptButtonClick = () => {
        setIsAcceptMeasurementShown((current) => !current)
    }

    const getClassName = (state) => {
        switch (state) {
            case 'verified':
                return 'pill_green'
            case 'rejected':
                return 'pill_red'
            case 'new':
                return 'pill_blue'
            default:
                return ''
        }
    }

    return (
        <Pane>
            <Dialog
                isShown={isShown}
                title="Zatwierdzanie odczytu"
                onCloseComplete={() => setIsImageShown(false)}
                hasFooter={false}
            >
                {imageUrl ? (
                    <Zoom>
                        <div className="dialog_image_container">
                            <img src={imageUrl} alt="meter" />
                        </div>
                    </Zoom>
                ) : (
                    <div className="dialog_image_container_empty">
                        <span>Brak zdjęcia</span>
                    </div>
                )}

                <div className="dialog">
                    <div className="span_box">
                        <span>Status:</span>
                        <Pill className={`pill ${getClassName(selectedMeasurement?.state)}`}>
                            {getState(selectedMeasurement?.state)}
                        </Pill>
                        <span>Wartość odczytu:</span>
                        <span>{selectedMeasurement?.value}</span>
                        <span>Data odczytu:</span>
                        <span>{dateConvert(selectedMeasurement?.updatedAt)}</span>
                        {selectedMeasurement?.state === 'rejected' && [
                            <span key="reject_label">Powód odrzucenia:</span>,
                            <span key="reject_value">{rejectReason}</span>
                        ]}
                    </div>

                    <div className="button_box">
                        <button
                            type="button"
                            className="button"
                            onClick={() => setIsImageShown(false)}
                        >
                            Anuluj
                        </button>
                        <button
                            type="button"
                            className="reject_button"
                            disabled={selectedMeasurement?.state !== 'new'}
                            onClick={handleOnRejectClick}
                        >
                            Odrzuć
                        </button>
                        <button
                            type="button"
                            className="accept_button"
                            disabled={selectedMeasurement?.state !== 'new'}
                            onClick={handleOnAcceptButtonClick}
                        >
                            Zatwierdź
                        </button>
                    </div>
                </div>
            </Dialog>
        </Pane>
    )
}
export default MeasurementVerifyDialog
