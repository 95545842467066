export class RegisterErrors {
    email = ''
    password = ''
    repeatPassword = ''
    consent = ''
    isError = false
}

export class LoginErrors {
    email = ''
    password = ''
    isError = false
}

export class RemindPasswordErrors {
    email = ''
    isError = false
}

export class MeterErrors {
    tenantMember = ''
    name = ''
    type = ''
    serialNumber = ''
    street = ''
    city = ''
    zipCode = ''
    isError = false

    clear() {
        this.tenantMember = ''
        this.name = ''
        this.type = ''
        this.serialNumber = ''
        this.street = ''
        this.city = ''
        this.zipCode = ''
        this.isError = false
    }

    setError() {
        this.isError = true
    }

    validate(meter, isBilling) {
        this.clear()

        if (!meter.name) {
            this.name = 'Nie podano nazwy licznika'
            this.setError()
        }

        if (!meter.tenantMember && isBilling) {
            this.tenantMember = 'Nie wybrano jednostki rozliczeniowej'
            this.setError()
        }

        if (!meter.serialNumber && isBilling) {
            this.serialNumber = 'Nie podano numeru seryjnego'
            this.setError()
        }

        if (!meter.type && isBilling) {
            this.type = 'Nie podano typu licznika'
            this.setError()
        }

        if (!meter.street && isBilling) {
            this.street = 'Nie podano ulicy'
            this.setError()
        }

        if (!meter.city && isBilling) {
            this.city = 'Nie podano miasta'
            this.setError()
        }

        if (!meter.zipCode && isBilling) {
            this.zipCode = 'Nie podano kodu pocztowego'
            this.setError()
        }
    }
}

export class MeasurementError {
    meter = ''
    value = ''
    state = ''
    isError = false

    clear() {
        this.meter = ''
        this.value = ''
        this.state = ''
        this.isError = false
    }

    setError() {
        this.isError = true
    }

    validate(measurement) {
        this.clear()

        if (!measurement.meter) {
            this.meter = 'Nie wybrano licznika'
            this.setError()
        }

        if (!measurement.value) {
            this.value = 'Nie podano wartości odczytu'
            this.setError()
        }
    }
}
