import Alert from '../../redux/models/Alert'

export const fromAlertApi = (apiObj) => {
    const responseData = apiObj.data

    const parsedData = Object.assign(
        {},
        ...responseData.results.map((alert, index) => {
            return {
                [alert.pk]: new Alert({ ...alert, index })
            }
        })
    )

    return {
        alerts: parsedData,
        count: responseData.count,
        nextPage: responseData.next,
        previousPage: responseData.previous
    }
}

export const toAlertApi = (uiObj) => {
    return {
        is_read: uiObj.isRead,
        is_muted: uiObj.isMuted
    }
}
