import { useSelector } from 'react-redux'

import AlertsModerator from './Alerts.moderator.view'

const Alerts = () => {
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    return isModerator ? <AlertsModerator /> : null
}

export default Alerts
