import Consumption from '../../redux/models/Consumption'

export const fromConsumptionApi = (apiObj) => {
    const responseData = apiObj.data

    const parsedData = Object.assign(
        {},
        ...responseData.results.map((consumption, index) => {
            return {
                [consumption.pk]: new Consumption({ ...consumption, index })
            }
        })
    )

    return {
        consumption: parsedData,
        count: responseData.count,
        nextPage: responseData.next,
        previousPage: responseData.previous
    }
}

export const toConsumptionApi = (uiObj) => {
    return {
        meter: uiObj.meter,
        value: uiObj.value,
        image: uiObj.image,
        state: uiObj.state
    }
}
