import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Table } from 'evergreen-ui'
import { useEffect, useState } from 'react'
import TopBar from '../../components/TopBar/TopBar'
import LeftMenu from '../../components/LeftMenu'
import ActiveFilters from '../../components/activeFilters'
import PaginationInput from '../../components/PaginationInput'
import RowLimit from '../../components/RowLimit'
import PaginationButtons from '../../components/PaginationButtons'
import Footer from '../../components/Footer'
import LoadingRows from '../../components/ListRows/LoadingRows'
import NoResultsRow from '../../components/ListRows/NoResultsRow'
import useApi from '../../hooks/useApi'
import { addConsumption } from '../../redux/slices/consumptionSlice'
import getPathArguments from '../../utils/pathIDs'
import { fromMeterApi } from '../../utils/Adapters/MeterApiAdapter'
import { addMeters } from '../../redux/slices/meterSlice'
import { fromConsumptionApi } from '../../utils/Adapters/ConsumptionApiAdapter'
import ConsumptionRow from '../../components/ListRows/ConsumptionRow'
import { addUsers } from '../../redux/slices/userSlice'
import fromGetUserTenantApi from '../../utils/Adapters/UserApiAdapter'

const ConsumptionTableRows = ({ consumption, meters, fetchStatus }) => {
    if (!fetchStatus) {
        return <LoadingRows elements={consumption} />
    }
    if (Object.values(consumption).length === 0) {
        return <NoResultsRow />
    }

    return Object.entries(consumption)
        .sort((a, b) => a[1].index - b[1].index)
        .map(([key, value]) => {
            return <ConsumptionRow key={key} consumption={value} meter={meters[value.meter]} />
        })
}

const ConsumptionCommon = ({ userRole }) => {
    const dispatch = useDispatch()

    const [urlParams, setUrlParams] = useSearchParams()

    const meters = useSelector((state) => state.meter.meters)
    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const consumption = useSelector((state) => state.consumption.consumption)
    const consumptionNextPage = useSelector((state) => state.consumption.nextPage)
    const consumptionPreviousPage = useSelector((state) => state.consumption.previousPage)
    const consumptionResultsCount = useSelector((state) => state.consumption.count)
    const rowLimit = useSelector((state) => state.profile.rowLimit)

    const [offset, setOffset] = useState(0)
    const [pageCount, setPageCount] = useState(1)

    // url params - filters
    const [userParam, setUserParam] = useState(null)
    const [meterParam, setMeterParam] = useState(null)
    const [measurementParam, setMeasurementParam] = useState(null)
    const [stateParam, setStateParam] = useState(null)
    const [startDateParam, setStartDateParam] = useState(null)
    const [endDateParam, setEndDateParam] = useState(null)
    const [measurementListParam, setMeasurementListParam] = useState(null)
    const [consumptionGranularity, setConsumptionGranularity] = useState('daily')
    const [searchParam, setSearchParam] = useState(null)
    const [typeParam, setTypeParam] = useState(null)

    // url params - sorting
    const [sortingParam, setSortingParam] = useState(null)

    const [consumptionsToExport, setConsumptionsToExport] = useState([])

    const buildURL = (limit) => {
        let baseURL = `/app/meters${
            userRole === 'moderator' ? `/${userRole}` : ''
        }/usage/${consumptionGranularity}/?limit=${limit}&offset=${offset}`

        // filters
        if (userParam !== null) baseURL += `&meter__tenant_member=${userParam}`
        if (meterParam !== null) baseURL += `&meter=${meterParam}`
        if (measurementParam !== null) baseURL += `&id__in=${measurementParam}`
        if (stateParam !== null) baseURL += `&state=${stateParam}`
        if (startDateParam !== null && endDateParam !== null) {
            baseURL += `&consumption_ts_after=${startDateParam}`
            baseURL += `&consumption_ts_before=${endDateParam}`
        }
        if (measurementListParam !== null) baseURL += `&id__in=${measurementListParam}`
        if (typeParam !== null) baseURL += `&meter__type=${typeParam}`
        if (searchParam !== null) baseURL += `&search=${searchParam}`

        // sorting
        if (sortingParam !== null) baseURL += `&ordering=${sortingParam}`

        return baseURL
    }

    const fetchConsumption = useApi({
        url: buildURL(rowLimit),
        method: 'GET',
        apiKey,
        fromApiAdapter: fromConsumptionApi,
        queryName: [
            'consumption',
            userParam,
            meterParam,
            measurementParam,
            measurementListParam,
            stateParam,
            sortingParam,
            startDateParam,
            endDateParam,
            typeParam,
            searchParam,
            offset,
            rowLimit,
            consumptionGranularity
        ],
        onSuccess: (data) => {
            dispatch(addConsumption(data))
        }
    })

    // TODO: set the limit to some huge number right now; move processing to backend later on
    const fetchAllConsumptions = useApi({
        url: buildURL(99999),
        method: 'GET',
        apiKey,
        fromApiAdapter: fromConsumptionApi,
        refetchOnWindowFocus: false,
        enabled: false,
        queryName: ['consumptionAll'],
        onSuccess: (data) => {
            setConsumptionsToExport(data)
        }
    })

    const fetchMetersByID = useApi({
        url: `/app/meters${
            userRole === 'moderator' ? `/${userRole}` : ''
        }/meter/?id__in=${getPathArguments(consumption, 'meter')}`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeterApi,
        queryName: ['metersByID', Object.keys(consumption)],
        enabled: fetchConsumption.isSuccess,
        onSuccess: (data) => {
            dispatch(addMeters(data))
        },
        keepPreviousData: true
    })

    useApi({
        url: `/app/tenants/${userRole}/tenant_member/?id__in=${getPathArguments(
            meters,
            'tenantMember'
        )}`,
        method: 'GET',
        apiKey,
        enabled: fetchMetersByID.isSuccess,
        fromApiAdapter: fromGetUserTenantApi,
        queryName: ['users', Object.keys(meters)],
        onSuccess: (data) => dispatch(addUsers(data))
    })

    const onPageClick = (page, direction) => {
        if (page !== null) {
            if (direction === 1) setPageCount((old) => old + 1)
            else setPageCount((old) => Math.max(old - 1, 0))
            const offsetPosition = page.indexOf('offset')
            if (offsetPosition !== -1) setOffset(page.substring(offsetPosition + 7, page.length))
            else setOffset(0)
        }
    }

    const handlePaginationInputonBlur = (e) => {
        setOffset(rowLimit * e - rowLimit)
        setPageCount(e)
    }

    const handleOnRemoveFilterClick = () => {
        if (!Number.isNaN(meterParam)) setUserParam(null)
        if (!Number.isNaN(meterParam)) setMeterParam(null)
    }

    const handleExportClick = async (downloadCsv) => {
        const { data } = await fetchAllConsumptions.refetch()

        // TODO: unify the logic with displaying row data
        const consumptionsWithMeters = Object.entries(data.consumption)
            .sort((a, b) => a[1].index - b[1].index)
            .map(([key, value]) => {
                return {
                    consumption: value,
                    meter: meters[value.meter]
                }
            })

        downloadCsv(
            ['Numer licznika', 'Typ licznika', 'Zużycie', 'Data odczytu'],
            consumptionsWithMeters.map((item) => [
                item.meter?.name,
                item.meter?.type,
                item.consumption?.consumption,
                item.consumption?.consumptionTimestamp
            ])
        )
    }

    useEffect(() => {
        if (!urlParams.has('consumption_range')) {
            urlParams.append('consumption_range', 'day')
            setUrlParams(urlParams)
        }

        return () => {
            setMeterParam('')
            setUserParam('')
        }
    }, [])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }
        setUserParam(null)
        setMeterParam(null)
        setMeasurementParam(null)
        setStateParam(null)
        setStartDateParam(null)
        setEndDateParam(null)
        setSortingParam(null)
        setMeasurementListParam(null)
        setTypeParam(null)
        setSearchParam(null)

        // filters
        const userParamGET = parseInt(urlParams.get('user_id'), 10)
        const meterParamGET = parseInt(urlParams.get('meter_id'), 10)
        const measurementParamGET = parseInt(urlParams.get('reading_id'), 10)
        const setStateParamGET = urlParams.get('state') || null
        const createdAtParamGET = urlParams.get('created_at') || null
        const consumptionTsParamGET = urlParams.get('consumption_ts') || null
        const consumptionRangeParamGET = urlParams.get('consumption_range') || 'day'
        const consumptionTsAfterParamGET = urlParams.get('consumption_ts_after') || null
        const consumptionTsBeforeParamGET = urlParams.get('consumption_ts_before') || null
        const measurementListParamGET = urlParams.get('reading_ids')
        const typeParamGET = urlParams.get('meter_type') || null
        const searchParamGET = urlParams.get('search') || null

        // sorting
        const sortingParamGET = urlParams.get('sorting') || null

        // filters
        if (typeParamGET !== null) setTypeParam(typeParamGET)
        if (!Number.isNaN(userParamGET)) setUserParam(userParamGET)
        if (!Number.isNaN(meterParamGET)) setMeterParam(meterParamGET)
        if (!Number.isNaN(measurementParamGET)) setMeasurementParam(measurementParamGET)
        if (measurementListParamGET !== null) setMeasurementListParam(measurementListParamGET)
        if (setStateParamGET !== null) setStateParam(setStateParamGET)
        if (consumptionTsAfterParamGET !== null) setStartDateParam(consumptionTsAfterParamGET)
        if (consumptionTsBeforeParamGET !== null) setEndDateParam(consumptionTsBeforeParamGET)
        if (consumptionRangeParamGET !== null) {
            switch (consumptionRangeParamGET) {
                case 'day': {
                    setConsumptionGranularity('daily')
                    break
                }
                case 'month': {
                    setConsumptionGranularity('monthly')
                    break
                }
                default:
                    break
            }
        }
        if (searchParamGET !== null) setSearchParam(searchParamGET)

        // sorting
        if (sortingParamGET !== null) {
            switch (sortingParamGET) {
                case 'created_at_asc':
                    setSortingParam('created_at')
                    break
                case 'created_at_desc':
                    setSortingParam('-created_at')
                    break
                case 'updated_at_asc':
                    setSortingParam('updated_at')
                    break
                case 'updated_at_desc':
                    setSortingParam('-updated_at')
                    break
                default:
                    setSortingParam(sortingParamGET)
                    break
            }
        }
    }, [urlParams])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }
    }, [rowLimit])

    return (
        <div className="main">
            <TopBar />
            <LeftMenu active="consumption" />
            <div className="main_content">
                <div className="title">
                    <div className="title_name">Zużycie</div>
                    <ActiveFilters
                        onRemoveClick={handleOnRemoveFilterClick}
                        consumptionFilters
                        onExportClick={handleExportClick}
                    />
                </div>

                <div className="container">
                    <div className="container_main">
                        <div className="container_cards">
                            <Table className="table">
                                <Table.Head className="header">
                                    <Table.TextHeaderCell className="table_title_col">
                                        Nazwa odbiorcy
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        ID odbiorcy
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Typ licznika
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Numer licznika
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Zużycie
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Zakres odczytów
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell
                                        className="table_title_col"
                                        flexBasis="170px"
                                        flexShrink={0}
                                        flexGrow={0}
                                    >
                                        Działania
                                    </Table.TextHeaderCell>
                                </Table.Head>
                                <Table.Body>
                                    <ConsumptionTableRows
                                        consumption={consumption}
                                        meters={meters}
                                        fetchStatus={fetchMetersByID.isSuccess}
                                    />
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="pagination_box">
                            <PaginationInput
                                pageCount={pageCount}
                                onBlur={handlePaginationInputonBlur}
                                resultCount={consumptionResultsCount}
                                apiLimit={rowLimit}
                            />
                            <RowLimit />
                        </div>
                        <PaginationButtons
                            pageCount={pageCount}
                            onPageClick={onPageClick}
                            prevPage={consumptionPreviousPage}
                            nextPage={consumptionNextPage}
                            resultCount={consumptionResultsCount}
                            apiLimit={rowLimit}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ConsumptionCommon
