import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { BsSpeedometer, BsClockHistory, BsFillPersonFill } from 'react-icons/bs'
import Zoom from 'react-medium-image-zoom'
import { Dialog, Pane, Table, Tooltip, Checkbox } from 'evergreen-ui'

import renderMeterIconType from '../../utils/renderMeterIconType'
import { renderState } from '../../utils/renderStateMeasurement'

const ConsumptionRow = ({ consumption = {}, meter = {} }) => {
    const user = useSelector((state) => state.user.users[meter.tenantMember])
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    const [isImageShown, setIsImageShown] = useState(false)
    const [imageUrl, setImageUrl] = useState()
    const [isSelected, setIsSelected] = useState(false)

    const isPrivate = meter.tenantMember === null || consumption.state === 'non_billed'

    const renderUserName = () => {
        if (isPrivate) return '-'
        if (user?.isCompany) return user?.companyName
        return user?.nameSurname
    }

    const renderConsumptionImageButton = () => {
        const renderValue = () => {
            const { unit, type } = meter
            const valuePrecision = type === 'heat' ? 5 : 3
            const value = `${consumption.consumption?.toFixed(valuePrecision)}`.replace('.', ',')

            return `${value} ${unit || ''}`
        }

        return (
            <div className="renderImageButtonDiv">
                <button
                    type="button"
                    className="pictureButton"
                    onClick={() => {}} // TODO: handle image when needed
                    disabled
                >
                    {renderValue()}
                </button>
            </div>
        )
    }

    return (
        <Table.Row className="row">
            <Table.TextCell>
                <Tooltip content="Przejdź do odbiorcy" showDelay={500}>
                    <Link to={`/users?user_id=${user?.pk}`}>{renderUserName()}</Link>
                </Tooltip>
            </Table.TextCell>
            <Table.TextCell isNumber>
                <span>{user?.externalId}</span>
            </Table.TextCell>
            <Table.TextCell isNumber>
                <span>{renderMeterIconType(meter, 16)}</span>
            </Table.TextCell>
            <Table.TextCell>
                <button type="button" className="pictureButton" disabled>
                    {meter.name}
                </button>
            </Table.TextCell>

            <Table.TextCell>{renderConsumptionImageButton()}</Table.TextCell>
            <Table.TextCell>{consumption.consumptionTimestamp}</Table.TextCell>
            {!isModerator && (
                <Table.TextCell isNumber>
                    <span>{renderState(consumption.state, 16)}</span>
                </Table.TextCell>
            )}
            <Table.TextCell flexBasis={isModerator ? '170px' : '140px'} flexShrink={0} flexGrow={0}>
                <div className="button_container">
                    <Tooltip content="Filtruj po odbiorcy" showDelay={500}>
                        <Link to={`/consumption?user_id=${meter.tenantMember}`}>
                            <button type="button" className="button">
                                <BsFillPersonFill size="18" />
                            </button>
                        </Link>
                    </Tooltip>
                    <Tooltip content="Przejdź do licznika" showDelay={500}>
                        <Link to={`/meters?meter_id=${meter.pk}`}>
                            <button type="button" className="button">
                                <BsSpeedometer size="18" />
                            </button>
                        </Link>
                    </Tooltip>
                    <Tooltip content="Pokaż historię odczytów" showDelay={500}>
                        <Link to={`/readings?meter_id=${meter.pk}`}>
                            <button type="button" className="button">
                                <BsClockHistory size="18" />
                            </button>
                        </Link>
                    </Tooltip>
                </div>
            </Table.TextCell>
            <Pane>
                <Dialog
                    isShown={isImageShown}
                    title="Zdjęcie licznika"
                    onCloseComplete={() => setIsImageShown(false)}
                    hasFooter={false}
                >
                    <Zoom>
                        <div className="dialog_image_container">
                            <img src={imageUrl} alt="meter" />
                        </div>
                    </Zoom>
                </Dialog>
            </Pane>
        </Table.Row>
    )
}

export default ConsumptionRow
