import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { registerLocale } from 'react-datepicker'
import pl from 'date-fns/locale/pl'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/store'

import App from './App'

import reportWebVitals from './utils/reportWebVitals'
import './styles/main.scss'
import './styles/components/pdfDialog.scss'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('pl', pl)

const root = ReactDOM.createRoot(document.getElementById('root'))
const history = createBrowserHistory({ window })

const queryClient = new QueryClient()

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    debug: process.env.REACT_APP_SENTRY_DEBUG === 'true',
    enabled: process.env.REACT_APP_SENTRY_ENABLED === 'true',
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    tunnel: process.env.REACT_APP_API_URL.concat('/app/sentry/tunnel/')
})

root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <QueryClientProvider client={queryClient}>
                <HistoryRouter history={history}>
                    <App />
                    <ReactQueryDevtools initialIsOpen={false} />
                </HistoryRouter>
            </QueryClientProvider>
        </PersistGate>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
