import {
    Dialog,
    Tablist,
    Tab,
    TextInputField,
    SelectField,
    FilePicker,
    toaster
} from 'evergreen-ui'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useApi from '../../hooks/useApi'
import { fromMeterApi } from '../../utils/Adapters/MeterApiAdapter'
import { MeasurementError } from '../../models/Auth/Errors'

import eventEmitter from '../../utils/eventEmitter'
import Measurement from '../../redux/models/Measurement'
import { toMeasurementApi } from '../../utils/Adapters/MeasurementApiAdapter'

export const AddMeasurementDialog = ({ isShown, setIsShown }) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)
    const [tabs] = useState(['Rozliczeniowy', 'Nierozliczeniowy'])

    const [meters, setMeters] = useState({})
    const [newMeasurement, setNewMeasurement] = useState(new Measurement({ state: 'new' }))
    const [measurementErrors, setMeasurementErrors] = useState(new MeasurementError())

    const apiKey = useSelector((state) => state.profile.profile.apiKey)

    useEffect(() => {
        switch (selectedTabIndex) {
            case 0:
                setNewMeasurement((prev) => ({ ...prev, state: 'new' }))
                break
            case 1:
            default:
                setNewMeasurement((prev) => ({ ...prev, state: 'non_billed' }))
                break
        }
    }, [selectedTabIndex])

    useApi({
        url: '/app/meters/meter/',
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeterApi,
        queryName: ['meters'],
        onSuccess: (data) => setMeters(data.meters)
    })

    const addMeasurementCall = useApi({
        url: '/app/meters/iot/measurement/',
        method: 'POST',
        apiKey,
        isFile: true,
        toApiAdapter: toMeasurementApi
    })

    const handleDialogClose = () => {
        setIsShown(false)
    }

    const handleTabChange = (newIndex) => {
        setSelectedTabIndex(newIndex)
    }

    const handleAddMeasurement = () => {
        const errors = new MeasurementError()
        errors.validate(newMeasurement)
        setMeasurementErrors(errors)

        if (!errors.isError) {
            addMeasurementCall.mutateAsync({ data: newMeasurement }).then(() => {
                toaster.success('Pomyślnie dodano nowy odczyt')
                setNewMeasurement((prev) => new Measurement({ state: prev.state }))
                eventEmitter.emit('reload')
            })
        }
    }

    const customDialogFooter = () => {
        return (
            <div className="dialogCustomFooter">
                <button type="button" className="button" onClick={handleAddMeasurement}>
                    Dodaj odczyt
                </button>
                <button type="button" className="rejectButton" onClick={handleDialogClose}>
                    Anuluj
                </button>
            </div>
        )
    }

    return (
        <Dialog
            isShown={isShown}
            title="Dodaj nowy odczyt"
            onCloseComplete={handleDialogClose}
            cancelLabel="Anuluj"
            width={700}
            footer={customDialogFooter}
        >
            <SelectField
                value={newMeasurement.meter}
                onChange={(e) => setNewMeasurement((prev) => ({ ...prev, meter: e.target.value }))}
                label="Licznik"
                required
                isInvalid={!!measurementErrors.meter}
                validationMessage={measurementErrors.meter || null}
            >
                <option value="" hidden>
                    Brak
                </option>
                {Object.values(meters).map((meter) => (
                    <option key={meter.pk} value={meter.pk}>
                        {meter.name}
                    </option>
                ))}
            </SelectField>
            <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
                {tabs.map((tab, index) => (
                    <Tab
                        aria-controls={`panel-${tab}`}
                        isSelected={index === selectedTabIndex}
                        key={tab}
                        onSelect={() => handleTabChange(index)}
                    >
                        {tab}
                    </Tab>
                ))}
            </Tablist>
            <div className="dialog__tab">
                <TextInputField
                    value={newMeasurement.value}
                    label="Wartość odczytu"
                    onChange={(e) =>
                        setNewMeasurement((prev) => ({ ...prev, value: e.target.value }))
                    }
                    type="number"
                    step="0.001"
                    required
                    isInvalid={!!measurementErrors.value}
                    validationMessage={measurementErrors.value || null}
                />
                <FilePicker
                    placeholder="Zdjęcie odczytu"
                    onChange={(files) =>
                        setNewMeasurement((prev) => ({ ...prev, image: files[0] }))
                    }
                    browseOrReplaceText={() => 'Wybierz plik'}
                />
            </div>
        </Dialog>
    )
}

export default AddMeasurementDialog
